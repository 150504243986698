.page {
	background-color: rgb(243, 246, 249);
	width: 100%;
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 12px;
}

.card-centered {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.success {
	@extend .card-centered;
	padding: 32px 8px;
}

.error {
	@extend .card-centered;
	padding: 16px;
	max-width: 360px;
}
